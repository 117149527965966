import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import AboveFooterSection from '../../components/AboveFooterSection'
import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { StaticImage } from 'gatsby-plugin-image'

const article = () => {
  const data = useStaticQuery(graphql`
    query ArticleSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "ArticleCaseStudy" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
            <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
              <div className="text-center">
                <StaticImage
                  src="../../resources/images/case_studies/article/article-logo.svg"
                  quality={100}
                  alt="Article"
                  placeholder="tracedSVG"
                />
              </div>
              <div className="w-75 mx-auto mt-4 mb-3">
                <h1 className="text-center my-2 lh-2-3">
                  How Article is winning market share using Apache Kafka
                </h1>
              </div>
              <p className="f-20 text-center px-5">
                A steep demand spike during the pandemic drove this digital
                retail leader to break up their monolith in weeks, to win market
                share from their competitors.
              </p>
              <div className="d-flex justify-content-center mb-3 mt-4">
                <StaticImage
                  src="../../resources/images/case_studies/article/hero-01.png"
                  quality={100}
                  placeholder="tracedSVG"
                  alt="Article hero"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-cyan mobile-margin-top mt-5">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-sm col-12 px-4 text-center">
              <h2 className="f-32 fw-900">
                220k <br /> new customers
              </h2>
              <p>
                across North America drove teams to protect customer experience
              </p>
            </div>
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900">
                70% YoY <br /> revenue growth
              </h2>
              <p>saw engineering optimizing order processing & fulfillment</p>
            </div>
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900">Weeks instead of years</h2>
              <p>to break up a monolith</p>
            </div>
            <div className="col-sm col-12 px-4 text-center mobile-margin-top">
              <h2 className="f-32 fw-900 pb-4">
                25 software <br /> engineers
              </h2>
              <p className="mt-4 pt-2 ipad-margin-reset ipad-padding-reset">
                access Kafka across 13 microservices without needing Kafka
                training
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <p>
                Article is focused on building the easiest way for people to
                furnish their spaces. They’re unique because they build software
                not only for themselves - but their suppliers, orchestrating the
                entire supply chain from customer order to final-mile delivery.
              </p>
              <p>
                Back in 2019, the engineering team launched a 2-5 year project
                to accelerate development by breaking up their monolith into
                domain applications and modernizing their systems. They
                initially looked to use Mulesoft, but as their requirements
                grew, they decided to use Apache Kafka as part of their
                event-driven architecture.
              </p>
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                No more sitting comfortably on streaming data
              </h3>
              <p>
                Then overnight, the world changed: They had to break up the
                monolith the next morning
              </p>
              <p>
                COVID-19 forced the whole world to go digital, and the eCommerce
                share of the furniture market significantly increased. During
                lockdown, people wanted to adorn their homes with new furniture.
              </p>
              <p>
                Luckily for Article, they were a digital-only retailer. But were
                they ready for the tsunami of orders to follow?
              </p>
              <p>
                No one could ever be ready for three months’ worth of Black
                Fridays.
              </p>
              <p>
                Engineering priorities had to change to protect customer
                experience at all costs. They had to optimize order processing,
                tracking and fulfillment with a new urgency. And not to mention
                improving communication services to ensure customers were
                constantly kept up-to-date with the progress of their orders.
              </p>
              <p>
                Developers could no longer afford to waste time answering these
                kinds of questions about Kafka:
              </p>
              <ul>
                <li>Why is there no consumer group for this topic?</li>
                <li>Has my event been published to this Kafka topic?</li>
                <li>Is my CDC Kafka Connect connector working correctly?</li>
                <li>What is the lag on this consumer?</li>
                <li>What is the schema for this topic?</li>
              </ul>

              <StaticImage
                className="mt-4 mb-3"
                quality={100}
                src="../../resources/images/case_studies/article/inline-image.png"
                placeholder="tracedSVG"
                alt="Arcticle"
              />

              <p>
                Luckily Laurent and his team of 25 were well-prepared with
                Lenses to focus on developing more than 13 microservices,
                because they had immediate self-service to operate Apache Kafka
                without needing a deep understanding of the technology.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-article py-5 mobile-margin-top mt-4">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “We knew that Kafka was critical for our business. But we also
                knew it would be difficult to operate. Using Lenses helps us
                know where to look for the data we need so we can see what’s
                working across systems and apps.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/case_studies/article/laurent-pernot.png"
                    quality={100}
                    alt="Laurent Pernot"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-2 mobile-padding-reset">
                    Laurent Pernot, Software Engineering Manager - Article
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mt-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-9 col-12">
              <h3 className="text-center">
                Confidently optimizing truck routing, fulfilment sources &
                purchase orders
              </h3>
              <p>
                Lenses gave the team a way to visualize and operate their
                streaming data landscape:
              </p>

              <ul className="red-checked-list pl-0">
                <li>
                  <span>
                    <p>
                      <b> Exploring Kafka topics:</b> Navigating Kafka is made
                      infinitely easier by using Lenses{' '}
                      <b>real-time data catalog</b> & SQL instead of command
                      line guesswork to troubleshoot their applications
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>
                      <b> Seeing Kafka consumers:</b> Lenses allows the team to
                      immediately see consumers, view the lag and check whether
                      data is moving through their applications.
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>
                      <b> Viewing & evolving schemas:</b> This can be done from
                      a UI with full security and auditing, and without needing
                      to understand a new set of APIs.
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    <p>
                      <b> Deploying Kafka Connect flows:</b> Engineers can use
                      Lenses to view, alert and deploy CDC or other Kafka
                      Connect connectors.
                    </p>
                  </span>
                </li>
              </ul>

              <h3 className="text-center my-3">
                Saving time spent on searching for streaming data
              </h3>
              <p>
                The software engineers at Article can now spend their time more
                wisely. Lenses has helped them shift from having to learn and
                operate complex technologies to focusing on increasing the
                velocity and quality of event-driven applications.
              </p>

              <p>
                Ultimately, it means the Article engineering team is doing more
                of what they do best: Building the best applications for
                ecommerce. This way, Article doesn’t only react in real-time to
                demand during a global crisis, but maintains market-leading
                customer experience for decades to come.
              </p>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Get your DataOps workspace for Apache Kafka:'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default article
